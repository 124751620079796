import axios from "axios";

/********** Special note!!! **********
 * This itterator is only here because the js protobuf adds the suffex "List" to array properties for some silly reason.
 * Untill there's a better solution, all cosmos results objects should be cleaned of that pesky word
 */
const iterate = (obj, stack, prevType) => {
  for (let property in obj) {
    if (Array.isArray(obj[property])) {
      //console.log(property , "(L="  + obj[property].length + ") is an array  with parent ", prevType, stack);
      iterate(obj[property], stack + property, "array");
      if (property.endsWith("List")) {
        obj[property.substring(0, property.length - 4)] = obj[property];
        delete obj[property];
      }
    } else {
      if (
        typeof obj[property] !== "string" &&
        typeof obj[property] !== "number"
      ) {
        if (prevType === "array") {
          //console.log(stack + "["  + property + "] is an object, item of " , prevType, stack);
          iterate(obj[property], stack + "[" + property + "].", "object");
        } else {
          //console.log(stack +    property  , "is " , typeof obj[property] , " with parent ", prevType, stack );
          iterate(obj[property], stack + property + ".", "object");
        }
      }
    }
  }
  return obj;
};

class CameraAPI {
  constructor() {
    this.baseTime = new Date();
    this.getFramesCallStack = []; // if the REACT_APP_CAMERA_ENV is set to local, use localhost, otherwise use the cloud server

    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_KUVA_API_URL}/camera/v1`,
      timeout: 10000
    });
  }

  static instance = null;

  static Instance = () => {
    if (!this.instance) {
      this.instance = new CameraAPI();
    }
    return this.instance;
  };

  // Allow auth header to be initialized after login
  setAuthToken = token => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  setBaseURI = url => {
    if (this.axiosInstance && process.env.REACT_APP_API_ORIGIN !== "local") {
      this.axiosInstance.defaults.baseURL = `${url}/v1`;
      console.log(
        "CameraAPI url set to: ",
        this.axiosInstance.defaults.baseURL
      );
    }
  };

  setBaseOrgHeader = baseOrg => {
    this.axiosInstance.defaults.headers.common["X-Organization"] = baseOrg;
  };

  get = (orgId, which) => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/cameras/${orgId}`,
      "color: #9954E3"
    );
    return this.axiosInstance.get(`cameras/${orgId}`, {
      params: {
        id: which
      },
      timeout: 30000
    });
  };

  getFrames = (camera, startDate, endDate) => {
    console.log(
      "url",
      `${this.axiosInstance.defaults.baseURL}/scans/${camera}`
    );
    return this.axiosInstance.get(`/scans/${camera}`, {
      params: {
        max_frames: startDate || endDate ? 2000 : 100,
        ...(startDate ? { start: startDate } : {}),
        ...(endDate ? { end: endDate } : {})
      }
    });
  };

  getTelemetry = (camera, startDate, endDate, maxResults) => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/cameratelemetry/${camera}/telemetry/`,
      "color: #00aa66"
    );
    return this.axiosInstance.get(`cameratelemetry/${camera}/telemetry/`, {
      params: {
        ...(startDate ? { start: startDate } : {}),
        ...(endDate ? { end: endDate } : {}),
        ...(maxResults ? { max: maxResults } : {})
      },
      timeout: 60000
    });
  };

  getLongTasks = () => {
    return this.axiosInstance.get("longtasks");
  };

  getModuleTwinForCamera = (camera, module) => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/cameramodule/${camera}/${module}`,
      "color: #9954E3"
    );
    return this.axiosInstance.get(`cameramodule/${camera}/${module}`);
  };

  getScheduleForCamera = camera => {
    console.log(
      `%c calling: ${this.axiosInstance.defaults.baseURL}/schedule/${camera}`,
      "color: #9954E3"
    );
    return this.axiosInstance.get(`schedule/${camera}`);
  };

  panCamera = (camera, direction, steps) => {
    console.log(`calling ${this.axiosInstance.defaults.baseURL}/pan/${camera}`);
    return this.axiosInstance.get(`pan/${camera}`, {
      params: {
        ...(direction ? { direction: direction } : {}),
        ...(steps ? { steps: steps } : {})
      }
    });
  };

  toggleCameraScan = which => {
    console.log(`calling: ${this.axiosInstance.defaults.baseURL}/togglescan`);
    return this.axiosInstance.get("togglescan", {
      params: {
        // code: "5xH8lNy6SqgjKMt6LxlAot0McOzJI9mXcAd2vKXGtnnrQJnBOLhX/A==",
        deviceId: which
      }
    });
  };

  toggleIlluminator = which => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/toggleilluminate`
    );
    return this.axiosInstance.get("toggleilluminate", {
      params: {
        // code: "U57Xgf/mBskT71mKhLjpaCLE/DnahoTnfQzXSr/FAxvLGVj/uymBkQ==",
        deviceId: which
      }
    });
  };
  darkcal = which => {
    console.log(`calling: ${this.axiosInstance.defaults.baseURL}/v1/calibrate`);
    return this.axiosInstance.get("calibrate", {
      params: {
        // code: "oUV/XsmSCLQx5J88/DYTd3gx26hya4avDBIyWzM0o4siCUOow9uRZA==",
        deviceId: which
      }
    });
  };

  capture = which => {
    console.log(`calling: ${this.axiosInstance.defaults.baseURL}/capture`);
    return this.axiosInstance.get("capture", {
      params: {
        // code: "uSy1hwZ2/iWmsUiM/L99t3MSbxtaPwpkwtN6qGuQeRjwrQCJAe4EOQ==",
        deviceId: which
      }
    });
  };

  /* FOV COMMANDS */
  requestPano = camera => {
    return this.axiosInstance.get(`getpanorama/${camera}/`);
  };

  panZero = (camera, deg, updatePano) => {
    return this.axiosInstance.post(`panzero/${camera}/`, {
      ...(deg !== null && deg !== undefined ? { degrees: deg } : {}),
      ...(updatePano ? { getPano: updatePano } : {})
    });
  };

  previewPoi = (camera, deg) => {
    return this.axiosInstance.get(`previewpoi/${camera}/`, {
      params: {
        ...(deg !== null && deg !== undefined ? { degrees: deg } : {})
      }
      // timeout: 60000
    });
  };

  configPoi = (camera, dwell_frames, pan_centers) => {
    return this.axiosInstance.post(`poi/${camera}/`, {
      dwell_frames,
      pan_centers
    });
  };

  getFovImage = (camera, degrees) => {
    if (degrees !== null && degrees !== undefined) {
      return this.axiosInstance.get(`fov/blobrgb/${camera}/${degrees}`);
    } else {
      return this.axiosInstance.get(`fov/blobrgb/${camera}`);
    }
  };

  /**
   * @description call the command azure function that will send a get request to resetalgo
   * resetalgo will clear algorithm history so that we can collect clean data.
   * @memberof CameraAPI
   * @param {string} deviceId
   * @returns {Promise}
   */
  resetalgo = deviceId => this.axiosInstance.get(`resetalgo/${deviceId}`);

  updateDeviceAttributes = (which, camUpdate) => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/camera/${which}`
    );
    return this.axiosInstance.put(`camera/${which}`, camUpdate);
  };

  /**
   * @function configFastPan - updates the device fast_pan config with the new configuration
   *
   * @param {string} deviceId - the device to update
   * @param {object} payload - the new fast_pan config
   * @returns {Promise}
   */
  configFastPan = (deviceId, payload) => {
    return this.axiosInstance.post(`fastpan/${deviceId}`, {
      enabled: payload.enabled,
      scan_speed: payload.scanSpeed.toString(),
      move_speed: payload.moveSpeed.toString()
    });
  };

  /**
   * @function setScanningSchedule - updates the device twin tags with the new schedule
   *
   * @param {string} deviceId - the device to update the schedule for
   * @param {string} onUtc - the time to switch scanning on in the format HH:MM
   * @param {string} offUtc - the time to switch scanning off in the format HH:MM
   * @returns {Promise}
   */
  setScanningSchedule = (deviceId, onUtc, offUtc) => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/schedule/${deviceId}`
    );
    return this.axiosInstance.post(`schedule/${deviceId}/`, { onUtc, offUtc });
  };
  /**
   * @function setIlluminatingSchedule - updates the device twin tags with the new schedule
   *
   * @param {string} deviceId - the device to update the schedule for
   * @param {string} illuminatoronutc - the time to switch illuminating on in the format HH:MM
   * @param {string} illuminatoroffutc - the time to switch illuminating off in the format HH:MM
   * @returns {Promise}
   */
  setIlluminatingSchedule = (deviceId, illuminatoronutc, illuminatoroffutc) => {
    console.log(
      `calling: ${this.axiosInstance.defaults.baseURL}/schedule/${deviceId}`
    );
    return this.axiosInstance.post(`schedule/${deviceId}`, {
      illuminatoronutc,
      illuminatoroffutc
    });
  };

  // This will cancel the previous call before exicuting a new one.
  // TODO: make this into an array of calls that we can loop through and cancle each time.

  makeRequestCreator = () => {
    return (
      camera,
      blobContainer,
      startDate,
      endDate,
      showDetections,
      minThreshold,
      showNonDetections,
      dontInterrupt
    ) => {
      if (this.getFramesCallStack.length > 0 && !dontInterrupt) {
        this.getFramesCallStack.forEach(call => call.cancel());
      }

      this.getFramesCallStack.push(axios.CancelToken.source());

      console.log("%c get scan results", "color: #9954E3");
      console.log(
        `%c time from last call: ${
          new Date().getTime() - this.baseTime.getTime()
        }ms`,
        "color: #9954E3"
      );

      this.baseTime = new Date();

      const params = {
        ...(startDate ? { start: startDate } : {}),
        ...(endDate ? { end: endDate } : {}),
        ...{ detections: showDetections },
        ...(minThreshold === ""
          ? { minThreshold: 0 }
          : { minThreshold: minThreshold }),
        ...{ nonDetections: showNonDetections } //,
        //        max_live_frames: 20,
      };

      this.baseTime = new Date();
      console.log(
        "c% +++++++++++++++++++++++++++++++++++++++++ \n",
        "color: #9954E3"
      );
      console.log(
        `calling: ${this.axiosInstance.defaults.baseURL}/scres/${camera}/${blobContainer} with parameters: `,
        params
      );

      return new Promise((resolve, reject) => {
        this.axiosInstance
          .get(`scres/${camera}/${blobContainer}`, {
            cancelToken:
              this.getFramesCallStack[this.getFramesCallStack.length - 1].token,
            params,
            timeout: 60 * 1000
          })
          .then(res => {
            if (res.data) {
              iterate(res.data, "", "Array");

              // console.log("\n\ncleaned Obj:\n\n", cleanedObject);
              console.log(
                `%c get scan results returned after ${
                  new Date().getTime() - this.baseTime.getTime()
                } ms`,
                "color: #9954E3"
              );
              resolve(res);
            }
          })
          .catch(err => {
            if (axios.isCancel(err)) {
              console.log("%c Request canceled", "color: #9954E3");
            } else {
              console.error("Request canceled", err);
              // handle error
              reject(err);
            }
          });
      });
    };
  };

  getScanResults = this.makeRequestCreator();
}

export default CameraAPI.Instance();
