import { useAuth0 } from "@auth0/auth0-react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { Typography } from "@mui/material";
import { useOrganizations } from "contentProviders/OrganizationsContextProvider";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";

import OrganizationAPI from "~/apis/OrganizationAPI";
import { setAuthToken } from "~/apis/api";
import { AbilityContext } from "~/components/common/Can";
import Loading from "~/components/common/Loading";
import { updateAbility } from "~/config/ability";
import { flags } from "~/constants/feature-flags";

import { Spinner, SpinnerContainer } from "./AppInitialize.styles";

const AppInitialize = ({
  appInitialized,
  setAppInitialized,
  setAppInitializing,
  setAppError
}) => {
  const ability = useContext(AbilityContext);
  const isGatewayFeatureOn = useFeatureIsOn(flags.USE_GATEWAY);

  const { getAccessTokenSilently, user } = useAuth0();

  const { setOrganizations, organizations, setSelectedOrg } =
    useOrganizations();

  const initializeApplication = async () => {
    try {
      console.log("================######## App Init ########============== ");

      //1. Get Auth0 token
      console.log(process.env.REACT_APP_AUTH0_API_ID);

      const token = await getAccessTokenSilently({});
      Cookies.set("kuva.auth.jwt", token, { expires: 1 });

      setAuthToken(token);

      if (!isGatewayFeatureOn)
        OrganizationAPI.setBaseURI(process.env.REACT_APP_ORG_API_URL);
      //2. Get organizations
      const orgData = await OrganizationAPI.getOrganizations();

      if (orgData.status === 204 || !orgData.data.length) {
        throw new Error("Default organization not found for this user!");
      }

      setOrganizations(orgData.data);
      //3. set up user permissions
      updateAbility(ability, user);

      //4. set app as initialized
      setAppInitialized(true);
      setAppInitializing(false);
      console.log(
        "================######## App Init done ########============== "
      );
    } catch (error) {
      console.error(error);
      setAppError({
        title: "Error!",
        message: error.message ?? "Unknown error",
        code: 123
      });
    }
  };

  useEffect(() => {
    setAppInitializing(true);
    initializeApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set previously selected org
    if (!appInitialized) return;
    try {
      const org = JSON.parse(localStorage.getItem("selected_org"));
      if (org) setSelectedOrg(org);
      else if (organizations?.length > 0) {
        setSelectedOrg(organizations[0]);
      }
    } catch (error) {
      console.log("error: ", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInitialized, setSelectedOrg]);

  return (
    <SpinnerContainer>
      <Spinner>
        <Typography variant="h6" sx={{ color: "white" }}>
          Initializing...
        </Typography>
        <Loading />
      </Spinner>
    </SpinnerContainer>
  );
};

AppInitialize.propTypes = {
  appInitialized: PropTypes.bool.isRequired,
  setAppInitialized: PropTypes.func.isRequired,
  setAppInitializing: PropTypes.func.isRequired,
  setAppError: PropTypes.func.isRequired
};

export default AppInitialize;
